<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    :color="color"
  >
    <!-- <el-form label-width="180px" label-position="top">
      <el-form-item label="Time Range">
        <el-date-picker class="ml-4 w-75" v-model="start_time" type="datetime" placeholder="From" />
      </el-form-item>
      <el-form-item>
        <el-date-picker class="ml-4 w-75" v-model="end_time" type="datetime" default-time="23:59:59" placeholder="To" />
      </el-form-item>
    </el-form> -->
    <el-date-picker
      class="ml-4 my-1"
      :clearable="false"
      v-model="dates"
      type="datetimerange"
      :picker-options="pickerOptions"
      range-separator="~"
      start-placeholder="Start date"
      end-placeholder="End date"
      align="right"
      @change="onChangeDate"
    >
    </el-date-picker>

    <template #header>
      <h6 class="modal-title text-dark">{{ title }}</h6>
      <CButtonClose @click="onCancel" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onOkay" color="primary">{{ok_text}}</CButton>
      <CButton @click="onCancel" color="secondary" v-if="cancel_text !== null">{{cancel_text}}</CButton>
    </template>
  </CModal>
</template>

<script>
import { mapState } from 'vuex';
import { zonedTimeToUtc } from 'date-fns-tz';
import utility from '@/utility';

export default {
  name: 'DatePickerModal',
  props: {
    enable_close_btn: {type:Boolean, default:false},
    title: { type: String, default: 'Confirm' },
    color: {type:String, default: 'light'},
    size: { type: String, default: 'md' },
    ok_text: {type: String, default: 'OK'},
    cancel_text: {type: String, default: 'Cancel'}
  },
  computed: {
    ...mapState([
      'capability'
    ])
  },
  data() {
    return {
      show: false,
      start_time: null,
      end_time: null,
      sts: null,
      ets: null,

      dates: [new Date(this.$utility.getTodayEnd().getTime() - 1000 * 60 * 60 * 24), this.$utility.getTodayEnd()],
      pickerOptions: {
        shortcuts: [
          {
            text: this.$t('cloud.title.ts_today'),
            onClick(picker) {
              let end = utility.getTodayEnd();
              let start = new Date(end.getTime() - 1000 * 60 * 60 * 24);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: this.$t('cloud.title.ts_d1'),
            onClick(picker) {
              let end = utility.getTodayEnd();
              let start = new Date(end.getTime() - 1000 * 60 * 60 * 24 * 2);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: this.$t('cloud.title.ts_thisweek'),
            onClick(picker) {
              let end = utility.getTodayEnd();
              let start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              start.setTime(start.getTime() - (start.getDay() * 24 * 60 * 60 * 1000));
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: this.$t('cloud.title.ts_lastweek'),
            onClick(picker) {
              let start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              start.setTime(start.getTime() - ((start.getDay() + 7) * 24 * 60 * 60 * 1000));
              let end = new Date(start.getTime() + 7 * 24 * 60 * 60 * 1000);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: this.$t('cloud.title.ts_last7d'),
            onClick(picker) {
              let end = utility.getTodayEnd();
              let start = new Date(end.getTime() - 1000 * 60 * 60 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      }
    };
  },
  methods: {
    open() {
      this.finalize();
      this.show = true;
    },
    finalize() {
      this.start_time = null;
      this.end_time = null;
      this.sts = null;
      this.ets = null;
      this.show = false;
      this.dates = [new Date(this.$utility.getTodayEnd().getTime() - 1000 * 60 * 60 * 24), this.$utility.getTodayEnd()];
    },
    onCancel() {
      this.$emit('cancel');
      this.finalize();
    },
    onOkay() {
      if (!this.dates || this.dates.length < 2) {
        this.$notify.warning({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.err_search_from'),
          offset: 30
        });
        return;
      }
      if (this.dates[1].getTime() - this.dates[0].getTime() > 7*24*60*60*1000) {
        this.$notify.warning({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.err_search_too_long'),
          offset: 30
        });
        return;
      }

      const startTime = this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', this.dates[0]);
      const endTime = this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', this.dates[1]);
      const timeZone = this.capability.user_profile.timezone;

      const startDate = zonedTimeToUtc(startTime, timeZone);
      const endDate = zonedTimeToUtc(endTime, timeZone);

      this.sts = startDate.getTime();
      this.ets = endDate.getTime();
      this.$emit('ok', {sts: this.sts, ets: this.ets});
      this.finalize();
    },

    onChangeDate() {
      const diff = this.dates[1] - this.dates[0];
      if (diff > 7*24*3600*1000) {
        this.$message.error(this.$t('cloud.title.err_search_too_long'));
        this.dates = [new Date(this.$utility.getTodayEnd().getTime() - 1000 * 60 * 60 * 24), this.$utility.getTodayEnd()];
        return;
      }
    }
  }
}
</script>