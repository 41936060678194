function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import axios from 'axios';
import Search from '@/containers/Search.vue';
export default {
  name: 'SearchFilterBranchModal',
  components: {
    Search: Search
  },
  data: function data() {
    return {
      loading: false,
      show: false,
      search_text: null,
      check_all: false,
      selected_list: [],
      list: [],
      ipp: 12,
      sort_column: 'name',
      sort_order: 'asc',
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      FIELDS: [{
        key: 'boxcheck',
        label: ''
      }, {
        key: 'name',
        label: this.$t('cloud.title.name')
      }, {
        key: 'region_name',
        label: this.$t('cloud.title.region')
      }, {
        key: 'address',
        label: this.$t('cloud.title.address')
      }]
    };
  },
  methods: {
    open: function open(branch_id_list) {
      if (branch_id_list) {
        this.selected_list = this.$utility.copy(branch_id_list);
      }

      this.show = true;
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      var params = {};
      params.page = this.pageCurrent;
      params.ipp = this.ipp;
      params.sort_column = this.sort_column;
      params.sort_order = this.sort_order;

      if (this.search_text) {
        params.search = this.search_text;
      }

      var query_string = '?' + qs.stringify(params);
      this.loading = true;
      axios.get("/api/branches/".concat(query_string)).then(function (response) {
        _this.pageTotal = response.data.page.total;
        _this.pageCurrent = response.data.page.current;
        _this.count = response.data.page.count;
        var list = response.data.list.map(function (el) {
          var found = _this.selected_list.find(function (al) {
            return al.id === el.id;
          });

          if (found) {
            el.checked = true;
            return el;
          }

          el.checked = false;
          return el;
        });
        _this.list = _this.$utility.copy(list);
      }).catch(function (error) {
        console.error(error);
      }).finally(function () {
        _this.loading = false;
      });
    },
    onUpdatePage: function onUpdatePage() {
      this.pageDirectSet = "" + this.pageCurrent;
      this.check_all = false;
      this.getList();
    },
    onChangePageDirectSet: function onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = "" + this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onCancel: function onCancel() {
      this.$emit('cancel');
      this.loading = false;
      this.show = false;
      this.search_text = null;
      this.check_all = false;
      this.param = null;
      this.selected_list = [];
      this.list = [];
      this.ipp = 15;
      this.sort_column = 'name';
      this.sort_order = 'asc';
      this.pageTotal = 1;
      this.pageCurrent = 1;
      this.pageDirectSet = 1;
      this.count = 0;
    },
    onOkay: function onOkay() {
      this.$emit('ok', this.selected_list);
      this.onCancel();
    },
    onCheck: function onCheck(branch) {
      if (branch.checked) {
        this.selected_list.push({
          id: branch.id,
          name: branch.name
        });
      } else {
        var pos = this.selected_list.findIndex(function (el) {
          return el.id === branch.id;
        });
        this.selected_list.splice(pos, 1);
      }

      this.list = this.$utility.copy(this.list);
    },
    onRemove: function onRemove(branch) {
      var _this2 = this;

      var pos = this.selected_list.findIndex(function (el) {
        return el.id === branch.id;
      });
      this.selected_list.splice(pos, 1);
      var list = this.$utility.copy(this.list);
      var l = list.map(function (el) {
        if (_this2.selected_list.find(function (al) {
          return al.id === el.id;
        })) {
          el.checked = true;
          return el;
        }

        el.checked = false;
        return el;
      });
      this.list = this.$utility.copy(l);
    },
    onCheckAll: function onCheckAll() {
      var _this3 = this;

      if (this.check_all) {
        var _iterator = _createForOfIteratorHelper(this.list),
            _step;

        try {
          var _loop = function _loop() {
            var branch = _step.value;

            var pos = _this3.selected_list.findIndex(function (el) {
              return el.id === branch.id;
            });

            if (pos >= 0) return "continue";

            _this3.selected_list.push({
              id: branch.id,
              name: branch.name
            });
          };

          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _ret = _loop();

            if (_ret === "continue") continue;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      } else {
        var _iterator2 = _createForOfIteratorHelper(this.list),
            _step2;

        try {
          var _loop2 = function _loop2() {
            var branch = _step2.value;

            var pos = _this3.selected_list.findIndex(function (el) {
              return el.id === branch.id;
            });

            if (pos < 0) return "continue";

            _this3.selected_list.splice(pos, 1);
          };

          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var _ret2 = _loop2();

            if (_ret2 === "continue") continue;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }

      var list = this.$utility.copy(this.list);
      var l = list.map(function (el) {
        if (_this3.selected_list.find(function (al) {
          return al.id === el.id;
        })) {
          el.checked = true;
          return el;
        }

        el.checked = false;
        return el;
      });
      this.list = this.$utility.copy(l);
    },
    onSearch: function onSearch(text) {
      this.search_text = text;
      this.getList();
    },
    onReset: function onReset() {
      var _this4 = this;

      this.$confirm(this.$t('msgbox.confirm.reset_filter', {
        f: this.$t('cloud.title.branch')
      }), this.$t('msgbox.confirm.title'), {
        confirmButtonText: this.$t('button.ok'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        _this4.check_all = false;
        _this4.selected_list = [];
        _this4.sort_column = 'name';
        _this4.sort_order = 'asc';
        _this4.pageTotal = 1;
        _this4.pageCurrent = 1;
        _this4.pageDirectSet = 1;

        _this4.$emit('ok', _this4.selected_list);

        _this4.onCancel();
      }).catch(function () {});
    }
  }
};