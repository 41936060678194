<template>
  <div
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div>
      <SearchFilter @change="onFilterChange" />
      <CCard>
        <CCardHeader>
          <CRow class="justify-content-between">
            <div class="mx-3 my-1">
              <strong style="line-height:36px;">{{$t('cloud.title.alarm')}}</strong>
              <span v-if="count > 0"> ({{count}}) </span>
              <CButton class="ml-4" color="light" @click="onExpandAll">
                <CIcon name="expand" />
              </CButton>
            </div>
            <div class="mx-3 my-1">
              <CDropdown
                :toggler-text="$t('button.export')"
                class="mr-2"
                color="secondary"
                placement="bottom-end"
              >
                <CDropdownHeader>{{$t('button.download')}}</CDropdownHeader>
                <CDropdownItem @click="onDownload('csv')">
                  <span class="mr-2"><CIcon name="csv" /></span>
                  CSV
                </CDropdownItem>
                <CDropdownItem @click="onDownload('xlsx')">
                  <span class="mr-2"><CIcon name="excel" /></span>
                  Excel
                </CDropdownItem>
                <CDropdownDivider/>
                <CDropdownItem @click="onReport">
                  <span class="mr-2"><CIcon name="report" /></span>
                  {{$t('cloud.title.periodic_report')}}
                </CDropdownItem>
              </CDropdown>
            </div>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="list"
            :fields="FIELDS"
            :items-per-page="10"
            clickableRows
            @row-clicked="onEventDetail"
          >
            <div slot="fmt_time-header">
              {{ $t('cloud.title.log_at') }}
              <CButton size="sm" @click="pageOrderReversal" :color="pageOrder==='asc'?'light':'info'" class="m-0 ml-1 d-inline-block">
                <CIcon v-if="pageOrder === 'desc'" name="cil-sort-numeric-up"/>
                <CIcon v-if="pageOrder === 'asc'" name="cil-sort-numeric-down"/>
              </CButton>
            </div>
            <td slot="event_name" slot-scope="{item}">
              <span v-if="item.src_event_name">{{item.src_event_name}}</span>
            </td>
            <td slot="color_label" slot-scope="{item}">
              <CIcon v-if="item.color_label" name="label" :class="`text-${$resource.COLOR_LABELS[item.color_label]}`" />
            </td>
            <td slot="event_meta" slot-scope="{item}">
              {{item.event_meta1 || ""}} {{item.event_meta2 || ""}} {{item.event_meta3 || ""}}
            </td>
            <template v-slot:details="{ item }">
              <CCollapse :show="item.detailsShow || forceExpand">
                <div v-if="intersectionObserver">
                  <AlarmDetail v-if="item.detailsShow || forceExpand" :alarm="item" :observer="intersectionObserver" />
                </div>
              </CCollapse>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>

      <CRow class="justify-content-end">
        <div class="px-3 mb-3">
          <span>{{$t('cloud.title.rows_per_page')}}&nbsp;:&nbsp;&nbsp;</span>
          <el-select v-model="pageIpp" style="width:90px;" @change="onChangePageIpp">
            <el-option :value="10" />
            <el-option :value="20" />
            <el-option :value="30" />
            <el-option :value="50" />
            <el-option :value="100" />
          </el-select>
        </div>
        <div class="flex-grow-1"></div>
        <CPagination
          v-if="pageTotal > 1"
          :activePage.sync="pageCurrent"
          :pages="pageTotal"
          @update:activePage="onUpdatePage"
        >
        </CPagination>
        <CInput v-if="pageTotal > 1" type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
      </CRow>
      <TopBtn />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import qs from 'qs'
import SearchFilter from './SearchFilter.vue'
import TopBtn from '@/components/TopBtn.vue'
import AlarmDetail from './AlarmDetail.vue'
import { format, addDays } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

export default {
  name: 'AlarmSearch',

  components: {
    SearchFilter,
    AlarmDetail,
    TopBtn
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    loadingText() {
      if (this.progress) {
        return `Data backup...${this.progress}`;
      }
      return `Loading...`;
    }
  },
  data () {
    return {
      loading: true,
      confirm_text: null,
      async_waiting_cnt: 0,
      progress: "",

      forceExpand: false,

      place_list: [],

      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      pageIpp: 10,
      pageOrder: 'asc',
      filters: {},
      count: 0,
      list: [],

      branch_list: [],
      FIELDS: [
        {key: 'color_label', label: ''},
        {key: 'fmt_time', _style: 'width:180px;'},
        {key: 'event_type_name', label: this.$t('cloud.title.event_type')},
        {key: 'event_name', label: this.$t('cloud.title.event_name')},
        {key: 'branch_name', label: this.$t('cloud.title.branch')},
        {key: 'device_name', label: this.$t('cloud.title.device')},
        {key: 'event_meta', label: this.$t('cloud.title.event_meta')}
      ],

      intersectionObserver: null,
      FILTER_TARGET_MODEL: 'alarm_view',
    }
  },
  mounted() {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.vueInstance.getSnapshots();
          this.intersectionObserver.unobserve(entry.target);
        }
      })
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });
    this.getViewSetting();
  },
  beforeDestroy() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  },
  methods: {
    getViewSetting() {
      axios.get(`/api/filter/?target_model=${this.FILTER_TARGET_MODEL}`)
        .then(response => {
          this.pageIpp = response.data.filters.page_ipp;
          this.forceExpand = response.data.filters.force_expand;
        })
    },
    putViewSetting() {
      axios.put(`/api/filter/`, {
        filter_items: {
          page_ipp: this.pageIpp,
          force_expand: this.forceExpand
        },
        target_model: this.FILTER_TARGET_MODEL
      })
    },
    getList(cb) {
      let query_string = '';
      let params = this.buildParam(this.pageIpp, this.pageCurrent, this.pageOrder);

      if (params) {
        query_string = '?' + qs.stringify(params);
      }

      this.list = [];
      this.loading = true;
      axios.get(`/api/alarm/${query_string}`)
        .then(result => {
          let list = this.$utility.copy(result.data.list);
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
          this.list = list.map(el => {
            const utcTime = el.ts;
            const timeZone = this.capability.user_profile.timezone;
            const alarm_date = utcToZonedTime(utcTime, timeZone);
            el.device_name = `${el.bridge_name}${el.ch?' [CH '+el.ch+']':''}`;
            el.fmt_time = this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', alarm_date);
            el.event_type_name = this.$t(`cloud.title.eventtype_${el.event_type}`);
            el.detailsShow = false;
            return el;
          });
          if (cb) {
            cb();
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    buildParam(ipp, page, order) {
      let params = {
        ipp: ipp,
        page: page,
        order: 'desc'
      }
      if (order) params.order = order;
      if (this.filters.branch) {
        params.branch = this.filters.branch;
      }
      if (this.filters.branch_list && this.filters.branch_list.length) {
        params.branch_list = this.filters.branch_list.map(el => el.id).join(',');
      }
      if (this.filters.event_types && this.filters.event_types.length) {
        params.event_types = this.filters.event_types.join(',');
      }
      if (this.filters.event_name) {
        params.event_name = this.filters.event_name;
      }
      if (this.filters.event_meta) {
        params.event_meta = this.filters.event_meta;
      }
      if (this.filters.device) params.device = this.filters.device;
      if (this.filters.device_list && this.filters.device_list.length) {
        params.device_list = this.filters.device_list.map(el => el.id).join(',');
      }
      if (this.filters.channels && this.filters.channels.length) params.channels = this.filters.channels.join(',');

      if (this.filters.ts_mode) {
        const timeZone = this.capability.user_profile.timezone;
        if (this.filters.ts_mode === 'today') {
          params.sts = this.startOfZonedDay(new Date(), timeZone).getTime();
          // params.order = 'asc';
        } else if (this.filters.ts_mode === 'd1') {
          const today = this.startOfZonedDay(new Date(), timeZone);
          const yesterday = this.startOfZonedDay(addDays(today, -1), timeZone);
          params.sts = yesterday.getTime();
          // params.order = 'asc';
        } else if (this.filters.ts_mode === 'd2') {
          const today = this.startOfZonedDay(new Date(), timeZone);
          const yesterday = this.startOfZonedDay(addDays(today, -2), timeZone);
          params.sts = yesterday.getTime();
          // params.order = 'asc';
        } else if (this.filters.ts_mode === 'h24') {
          params.sts = Date.now() - (1000 * 60 * 60 * 24);
          // params.order = 'asc';
        }
      } else if (this.filters.sts && this.filters.ets) {
        params.sts = this.filters.sts;
        params.ets = this.filters.ets;
      }
      if (this.filters.color_labels && this.filters.color_labels.length) {
        params.color_labels = this.filters.color_labels.join(",")
      }
      return params;
    },
    // getBranchList() {
    //   let query_string = '';
    //   const params = {
    //     // sort: 'created_at',
    //     // order: 'desc',
    //     // search: this.searchText,
    //     ipp: 100,
    //     page: 1
    //   };
    //   if (params) {
    //     query_string = '?' + qs.stringify(params)
    //   }
    //   axios.get(`/api/branches/${query_string}`)
    //     .then(result => {
    //       this.branch_list = result.data.list;
    //       // this.pageTotal = result.data.page.total;
    //       // this.pageCurrent = result.data.page.current;
    //       // this.count = result.data.page.count;
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     })
    // },
    onFilterChange(payload) {
      this.filters = this.$utility.copy(payload);
      this.getList();
    },
    onEventDetail(alarm) {
      for (const item of this.list) {
        if (item.id !== alarm.id)
          item.detailsShow = false;
      }
      alarm.detailsShow = !alarm.detailsShow;
    },
    onPrev(alarm) {
      const curId = this.list.findIndex(el => el.id === alarm.id);
      if (curId > 0) {
        this.onEventDetail(this.list[curId-1]);
      } else if (this.pageCurrent > 1) {
        this.pageCurrent--;
        this.onUpdatePage();
        this.getList(() => {
          this.onEventDetail(this.list[this.list.length-1]);
        });
      } else {
        this.$alert(this.$t('cloud.title.no_more_alarms'), this.$t('cloud.title.confirm_title'));
      }
    },
    onNext(alarm) {
      const curId = this.list.findIndex(el => el.id === alarm.id);
      if (curId < this.list.length-1) {
        this.onEventDetail(this.list[curId+1]);
      } else if (this.pageCurrent < this.pageTotal) {
        this.pageCurrent++;
        this.onUpdatePage();
        this.getList(() => {
          this.onEventDetail(this.list[0]);
        });
      } else {
        this.$alert(this.$t('cloud.title.no_more_alarms'), this.$t('cloud.title.confirm_title'));
      }
    },

    onDownload(t) {
      let postfix = '';
      if (this.count > 50000) {
        postfix = '_limit';
      }
      this.$confirm(this.$t(`cloud.title.confirm_backup${postfix}`, {limit:50000}), this.$t('cloud.title.confirm_title'), {
        confirmButtonText:this.$t('button.ok'),
        cancelButtonText:this.$t('button.cancel'),
        type:'warning'
      })
      .then(() => {
        let query_string = '';
        let params = this.buildParam(this.pageIpp, this.pageCurrent, this.pageOrder);
        params.download = t;

        if (params) {
          query_string = '?' + qs.stringify(params);
        }
        this.loading = true;
        axios.get(`/api/alarm/${query_string}`)
          .then(result => {
            this.waitAsyncReq(result.data);
          })
          .catch(error => {
            console.error(error);
            this.loading = false;
          })
      })
    },
    waitAsyncReq(task) {
      this.async_waiting_cnt += 1;
      if (this.async_waiting_cnt > 60) {
        this.$notify.error({
          title: this.$t('cloud.title.request_fail_title'),
          message: this.$t('cloud.title.request_fail_content'),
          offset: 30
        });
        this.loading = false;
        this.async_waiting_cnt = 0;
        this.progress = "";
        return;
      }
      this.loading = true;
      setTimeout(() => {
        axios.get(`/api/async/check-result/?task_id=${task.task_id}`)
          .then(response => {
            const is_done = response.data.result;
            this.progress = `${response.data.progress.index}/${response.data.progress.item_count}`;
            if (response.data.progress.index === 0) {
              this.progress = null;
            }
            if (is_done) {
              this.getFile(task);
            } else {
              this.waitAsyncReq(task);
            }
          })
      }, 1000);
    },
    getFile(task) {
      axios({
          url: `/api/async/download/?fname=${task.fname}`,
          method: 'GET',
          responseType: 'blob',
      }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', task.fname);
          document.body.appendChild(link);
          link.click();
      }).finally(() => {
        this.loading = false;
        this.async_waiting_cnt = 0;
        this.progress = "";
      })
    },
    onReport() {
      this.$router.push(`/report/alarm`);
    },
    onExpandAll() {
      for (const item of this.list) {
        item.detailsShow = false;
      }
      this.forceExpand = !this.forceExpand;
      this.putViewSetting();
    },
    onChangePageIpp() {
      this.pageCurrent = 1;
      this.onUpdatePage();
      this.putViewSetting();
    },
    startOfZonedDay(date, timeZone) {
      const zonedDate = utcToZonedTime(date, timeZone);
      const startString = format(zonedDate, 'yyyy-MM-dd') + ' 00:00:00';
      return zonedTimeToUtc(startString, timeZone);
    },
    pageOrderReversal() {
      if (this.pageOrder === 'desc') this.pageOrder = 'asc';
      else this.pageOrder = 'desc';
      this.getList();
    }
  }
}
</script>
