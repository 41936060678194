<template>
  <div>
    <DatePickerModal ref="DatePickerModal" :title="$t('cloud.title.stat_custom')" :ok_text="$t('button.ok')" :cancel_text="$t('button.cancel')" @ok="onTsTimestamps" />
    <SearchFilterBranchModal ref="SearchFilterBranchModal" @ok="onBranchChange" />
    <SearchFilterDeviceModal ref="SearchFilterDeviceModal" @ok="onDeviceChange" />
    <CCard>
      <CCardHeader>
        <div class="filter_row">
          <div class="filter_label d-md-down-none" style="border: 0;"></div>
          <div class="ml-4 my-1">
            <el-tag class="mr-1" v-for="f in filters" :key="`filter-tags-${f.id}`" :type="f.type" :closable="f.id !== 'ts_mode' || f.content !== $t('cloud.title.filter_ts_today')" @close="onClose(f.id)">{{f.content}}</el-tag>
          </div>
        </div>
      </CCardHeader>
      <CCardBody>
        <!-- <CButton v-if="collapse" @click="onFold"
          color="secondary"
          size="sm"
          class="px-3 filter_detail_onoff">
          <svgicon name="keyboard_arrow_up" width="18px" height="18px" />
        </CButton>
        <CButton v-else @click="onUnfold"
          color="secondary"
          size="sm"
          class="px-3 filter_detail_onoff">
          <svgicon name="keyboard_arrow_down" width="18px" height="18px" />
        </CButton> -->
        <CRow v-if="!loading">
          <CCol>
            <div class="filter_row">
              <div class="filter_label d-md-down-none">{{$t('cloud.title.event')}}</div>
              <el-select
                class="ml-4 my-1 filter_input_size"
                v-model="filter.event_types"
                :placeholder="$t('cloud.title.event')"
                filterable
                clearable
                multiple
                collapse-tags
                @change="onEventTypeChange"
                @visible-change="onVisibleChange"
              >
                <el-option-group
                  v-for="group in $resource.EVENT_TYPES"
                  :key="`event-type-service-${group.group_name_key}`"
                  :label="$t(group.group_name_key)"
                >
                  <el-option
                    v-for="event_type in filterEventTypes(group.event_types)"
                    :key="`event-type-opts-${event_type.label_key}`"
                    :label="$t(event_type.label_key)"
                    :value="event_type.value"
                  />
                </el-option-group>
              </el-select>
            </div>
            <CCollapse :show="collapse">
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.color_label')}}</div>
                <el-checkbox-group v-model="filter.color_labels" class="ml-4 my-2" @change="onFilterChange">
                  <el-checkbox :label="1"><CIcon name="label" class="text-secondary" size="lg" /></el-checkbox>
                  <el-checkbox :label="2"><CIcon name="label" class="text-success" size="lg" /></el-checkbox>
                  <el-checkbox :label="3"><CIcon name="label" class="text-warning" size="lg" /></el-checkbox>
                  <el-checkbox :label="4"><CIcon name="label" class="text-danger" size="lg" /></el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.event_name')}}</div>
                <el-input class="ml-4 my-1 filter_input_size" v-model="filter.event_name" :placeholder="$t('cloud.title.event_name')" @change="onFilterChange" />
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.branch')}}</div>
                <CButton :color="filter.branch_list.length?'info':'light'" class="ml-4 my-1" size="sm" @click="openBranch">{{$t('cloud.title.setting_branch_filter')}}</CButton>
                <span v-if="filter.branch_list.length" style="line-height:50px;margin-left:10px;"><CIcon name="cil-check-alt" class="text-success" size="lg" /></span>
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.device')}}</div>
                <!-- <el-input class="ml-4 my-1 filter_input_size_small" v-model="filter.device" :placeholder="$t('cloud.title.name_or_mac')" @change="onFilterChange" /> -->
                <CButton :color="filter.device_list.length?'info':'light'" class="ml-4 my-1" size="sm" @click="openDevice">{{$t('cloud.title.setting_device_filter')}}</CButton>
                <span v-if="filter.device_list.length" style="line-height:50px;margin-left:10px;" class="mr-4"><CIcon name="cil-check-alt" class="text-success" size="lg" /></span>
                <el-select class="ml-1 my-1 filter_select"
                  v-model="filter.channels"
                  placeholder="CH"
                  multiple clearable collapse-tags
                  @change="onChChange"
                  @visible-change="onChVisibleChange"
                >
                  <el-option v-for="i in $utility.Range(1, 9)" :label="`CH ${i}`" :value="i" :key="`channel-opts-${i}`" />
                </el-select>
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.event_meta')}}</div>
                <el-input class="ml-4 my-1 filter_input_size" v-model="filter.event_meta" :placeholder="$t('cloud.title.event_meta')" @change="onFilterChange" />
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.time_range')}}</div>
                  <el-tooltip class="ml-4 my-1" effect="dark" :content="$t('cloud.title.filter_ts_today')" placement="top">
                    <el-button :type="filter.ts_mode === 'today'?'primary':''" circle @click="onTsToday">
                      <svgicon name="today" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="ml-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_yesterday')" placement="top">
                    <el-button :type="filter.ts_mode === 'd1'?'primary':''" circle @click="onTsMinus1">
                      <svgicon name="exposure_neg_1" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="ml-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_before_yesterday')" placement="top">
                    <el-button :type="filter.ts_mode === 'd2'?'primary':''" circle @click="onTsMinus2">
                      <svgicon name="exposure_neg_2" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="mx-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_last_24_hours')" placement="top">
                    <el-button :type="filter.ts_mode === 'h24'?'primary':''" circle @click="onTs24Hours">
                      <svgicon name="replay_circle_filled" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <div style="border-left: 1px solid #eee;">
                  <el-tooltip class="ml-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_manual')" placement="top">
                    <el-button type="info" circle @click="onTsManual">
                      <svgicon name="access_time" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                </div>
              </div>
            </CCollapse>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import DatePickerModal from '@/components/modals/DatePickerModal.vue';
import SearchFilterBranchModal from '@/components/modals/SearchFilterBranchModal.vue';
import SearchFilterDeviceModal from '@/components/modals/SearchFilterDeviceModal.vue';

export default {
  name: 'AlarmSearchFilter',
  components: {
    DatePickerModal,
    SearchFilterBranchModal,
    SearchFilterDeviceModal,
  },

  data() {
    return {
      loading: true,
      filter: {
        branch: null,
        branch_list: [],
        event_types: [],
        event_name: null,
        event_meta: null,
        device: null,
        device_list: [],
        channels: [],
        ts_mode: null,
        sts: null,
        ets: null,
        color_labels: []
      },
      collapse : true,

      et_visible: false,
      ch_visible: false,

      FILTER_TARGET_MODEL: 'alarm',
      EVENT_TYPES: []
    }
  },

  mounted() {
    this.getSearchFilter();
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    filters() {
      let rtn = [];

      if (this.filter.event_types && this.filter.event_types.length) {
        let event_types = [];
        for (const event_type of this.filter.event_types) {
          for (let event_type_group of this.$resource.EVENT_TYPES) {
            let et = event_type_group.event_types.find(el => el.value === event_type);
            if (et) {
              event_types.push(this.$t(et.label_key));
            }
          }
        }
        let tag_text = event_types[0];
        if (this.filter.event_types.length > 1) {
          tag_text = `${tag_text} +${this.filter.event_types.length-1}`;
        }
        rtn.push({
          id: 'event_types',
          type: 'primary',
          content: tag_text
        });
      }

      if (this.filter.event_name) {
        rtn.push({
          id: 'event_name',
          type: 'primary',
          content: `${this.$t('cloud.title.event_name')}:${this.filter.event_name}`
        });
      }

      if (this.filter.event_meta) {
        rtn.push({
          id: 'event_meta',
          type: 'primary',
          content: `${this.$t('cloud.title.event_meta')}:${this.filter.event_meta}`
        });
      }

      if (this.filter.branch) {
        rtn.push({
          id: 'branch',
          type: 'primary',
          content: `${this.$t('cloud.title.branch')}:${this.filter.branch}`
        });
      }

      if (this.filter.branch_list.length) {
        if (this.filter.branch_list.length === 1) {
          rtn.push({
            id: 'branch_list',
            type: 'primary',
            content: `${this.$t('cloud.title.branch')}:${this.filter.branch_list[0].name}`
          });
        } else {
          rtn.push({
            id: 'branch_list',
            type: 'primary',
            content: `${this.$t('cloud.title.branch')}:${this.filter.branch_list[0].name} +${this.filter.branch_list.length-1}`
          });
        }
      }

      if (this.filter.device) {
        rtn.push({
          id: 'device',
          type: 'primary',
          content: `${this.$t('cloud.title.device')}:${this.filter.device}`
        });
      }

      if (this.filter.device_list.length) {
        if (this.filter.device_list.length === 1) {
          rtn.push({
            id: 'device_list',
            type: 'primary',
            content: `${this.$t('cloud.title.device')}:${this.filter.device_list[0].name}`
          });
        } else {
          rtn.push({
            id: 'device_list',
            type: 'primary',
            content: `${this.$t('cloud.title.device')}:${this.filter.device_list[0].name} +${this.filter.device_list.length-1}`
          });
        }
      }

      if (this.filter.channels && this.filter.channels.length) {
        let channels = this.filter.channels.join(',');
        rtn.push({
          id: 'channels',
          type: 'primary',
          content: `CH:${channels}`
        })
      }

      if (this.filter.color_labels.length) {
        rtn.push({
          id: 'color_labels',
          type: 'primary',
          content: `${this.$t('cloud.title.color_label')} ${this.filter.color_labels.join(',')}`
        });
      }

      if (this.filter.ts_mode) {
        if (this.filter.ts_mode === 'today') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: this.$t('cloud.title.filter_ts_today')
          });
        } else if (this.filter.ts_mode === 'd1') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: this.$t('cloud.title.filter_ts_yesterday')
          });
        } else if (this.filter.ts_mode === 'd2') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: this.$t('cloud.title.filter_ts_before_yesterday')
          });
        } else if (this.filter.ts_mode === 'h24') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: this.$t('cloud.title.filter_ts_last_24_hours')
          });
        }
      }

      if (this.filter.sts && this.filter.ets) {
        const timeZone = this.capability.user_profile.timezone;
        const sdate = new Date(this.filter.sts);
        const edate = new Date(this.filter.ets);
        const tz_sdate = utcToZonedTime(sdate, timeZone);
        const tz_edate = utcToZonedTime(edate, timeZone);
        const startString = format(tz_sdate, 'yyyy-MM-dd HH:mm:ss');
        const endString = format(tz_edate, 'yyyy-MM-dd HH:mm:ss');

        rtn.push({
          id: 'ts',
          type: 'info',
          content: `Time: ${startString} ~ ${endString}`
        });
      }
      return rtn;
    },
    isNoFilter() {
      if (!this.filter.ts_mode && !this.filter.sts && !this.filter.ets) return true;
      return false;
    }
  },
  methods: {
    async getSearchFilter() {
      this.loading = true;
      try {
        let res_filter_items = await axios.get(`/api/filter/?target_model=${this.FILTER_TARGET_MODEL}`);
        for (const f in res_filter_items.data.filters) {
          this.filter[f] = res_filter_items.data.filters[f];
        }
      } catch (error) {
        console.log(error.response.data);
      }
      let res_etypes = await axios.get(`/api/alarm/filter/opts/`);
      this.EVENT_TYPES = this.$utility.copy(res_etypes.data.event_types);
      this.onFilterChange();
      this.loading = false;
    },
    putSearchFilter() {
      this.loading = true;
      axios.put(`/api/filter/`, {
        target_model: this.FILTER_TARGET_MODEL,
        filter_items: this.filter
      })
        .finally(() => {
          this.loading = false;
        })
    },
    onFilterChange() {
      if (this.isNoFilter) {
        this.filter.sts = null;
        this.filter.ets = null;
        this.filter.ts_mode = 'today';
        this.$emit('change', this.filter);
      } else {
        this.$emit('change', this.filter);
      }
      this.putSearchFilter();
    },
    onTsToday() {
      this.filter.sts = null;
      this.filter.ets = null;
      this.filter.ts_mode = 'today';
      this.onFilterChange();
    },
    onTsMinus1() {
      this.filter.sts = null;
      this.filter.ets = null;
      this.filter.ts_mode = 'd1';
      this.onFilterChange();
    },
    onTsMinus2() {
      this.filter.sts = null;
      this.filter.ets = null;
      this.filter.ts_mode = 'd2';
      this.onFilterChange();
    },
    onTs24Hours() {
      this.filter.sts = null;
      this.filter.ets = null;
      this.filter.ts_mode = 'h24';
      this.onFilterChange();
    },
    onTsManual() {
      this.$refs.DatePickerModal.open();
    },
    onTsTimestamps({sts, ets}) {
      this.filter.ts_mode = null;
      this.filter.sts = sts;
      this.filter.ets = ets;
      this.onFilterChange();
    },

    onClose(filter_id) {
      switch(filter_id) {
        case 'event_types':
          this.filter.event_types = [];
          break;
        case 'event_name':
          this.filter.event_name = null;
          break;
        case 'event_meta':
          this.filter.event_meta = null;
          break;
        case 'branch':
          this.filter.branch = null;
          break;
        case 'branch_list':
          this.filter.branch_list = [];
          break;
        case 'device':
          this.filter.device = null;
          break;
        case 'device_list':
          this.filter.device_list = [];
          break;
        case 'channels':
          this.filter.channels = [];
          break;
        case 'color_labels':
          this.filter.color_labels = [];
          break;
        case 'ts_mode':
          this.filter.ts_mode = null;
          this.filter.sts = null;
          this.filter.ets = null;
          break;
        case 'ts':
          this.filter.ts_mode = null;
          this.filter.sts = null;
          this.filter.ets = null;
          break;
      }
      this.onFilterChange();
    },

    onFold() {
      this.collapse = false;
    },
    onUnfold() {
      this.collapse = true;
    },

    onClearChannels() {
      this.filter.channels = [];
      this.onFilterChange();
    },

    filterEventTypes(event_types) {
      let filtered_event_types = event_types.filter(el => {
        for (const et of this.EVENT_TYPES) {
          if (et === el.value) {
            return true;
          }
        }
        return false;
      });
      return filtered_event_types;
    },

    onEventTypeChange() {
      if (this.et_visible) return;
      this.onFilterChange();
    },
    onVisibleChange(visible) {
      if (visible) {
        this.et_visible = true;
        return;
      }
      this.et_visible = false;
      this.onFilterChange();
    },

    onChChange() {
      if (this.ch_visible) return;
      this.onFilterChange();
    },
    onChVisibleChange(visible) {
      if (visible) {
        this.ch_visible = true;
        return;
      }
      this.ch_visible = false;
      this.onFilterChange();
    },
    openBranch() {
      this.$refs.SearchFilterBranchModal.open(this.$utility.copy(this.filter.branch_list));
    },
    onBranchChange(branches) {
      this.filter.branch_list = branches.map(el => {return {id:el.id, name:el.name}});
      this.onFilterChange();
    },
    openDevice() {
      this.$refs.SearchFilterDeviceModal.open(this.$utility.copy(this.filter.device_list));
    },
    onDeviceChange(devices) {
      this.filter.device_list = devices.map(el => {return {id:el.id, name:el.name}});
      this.onFilterChange();
    },
  }
}
</script>

<style lang="scss" scoped>
.filter_row {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  height:50px;
}
.filter_label {
  width:120px;
  margin-right:10px;
  line-height:45px;
  border-right:1px solid #d8dbe0;
  font-weight: bold;
}
.filter_select {
  width:150px;
  margin-top : 5px;
  margin-left:10px;
}
.filter_picker {
  margin-top : 2.3px;
  margin-left:10px;
}
.filter_input_size_small {
  width: 200px;
}
.filter_input_size {
  width: 240px;
}
.filter_detail_onoff {
  position:absolute;
  left:50%;
  bottom:-14px;
  margin-left:-26px;
}
.filter_tags {
  position: absolute;
  left: 500px;
  top: 24px;
}
</style>
